<template>
  <div>
    <div
      class="scroll_container"
      style="padding-bottom: 30px"
    >
      <table class="table">
        <thead>
          <tr>
            <th
              v-for="item in headItems"
              :key="item.key"
            >
              {{ $t(item.name) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in bodyItems"
            :key="item.key"
            :class="{ error: item.id_onesignal == 'error' }"
          >
            <td v-if="step == 1">
              {{ item.id }}
            </td>
            <td v-else>
              {{ item.id_push }}
            </td>

            <td v-if="step === 1">
              {{ item.text.name }}
            </td>
            <td v-if="step === 2">
              {{ item.text.headings.en }}
            </td>
            <td v-if="step === 2">
              {{ item.text.contents.en }}
            </td>
            <td
              v-if="step === 1 || step === 2"
              style="width: 100px"
            >
              <div
                class="all__geo"
                @click="watchGeo(item.geo, item.id, item.id_push, item.text.headings.en)"
              >
                <img src="../../assets/images/info.svg">
              </div>
            </td>
            <td v-if="step === 3">
              {{ item.geo }}
            </td>
            <td v-if="step === 4">
              {{ item.app.name }}
            </td>
            <td>{{ item.successful_sum }}</td>
            <td>{{ item.successful_sum - item.errored_sum - item.failed_sum }}</td>
            <td>{{ item.errored_sum }}</td>
            <td>{{ item.failed_sum }}</td>
            <td>{{ item.converted_sum }}</td>
            <td>{{ item.ctr }}</td>
            <td v-if="step === 1">
              <div
                class="next__step"
                @click="goToStep(item)"
              >
                {{ $t('pushes') }}
                <img src="../../assets/images/double_arrow.svg">
              </div>
            </td>
            <td v-if="step === 3">
              <div
                class="next__step"
                @click="goToApps(item.geo)"
              >
                {{ $t('to_apps') }}
                <img src="../../assets/images/double_arrow.svg">
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="totalItems">
          <tr>
            <td>{{ $t('total') }}</td>
            <td v-if="step === 2" />
            <td v-if="step === 1 || step === 2" />
            <td />

            <td>{{ totalItems.send }}</td>
            <td>{{ totalItems.delivery }}</td>
            <td>{{ totalItems.errored }}</td>
            <td>{{ totalItems.failed }}</td>
            <td>{{ totalItems.converted }}</td>
            <td>{{ totalItems.ctr }}</td>
            <td />
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: `TableCustom`,
  props: ["headItems", "bodyItems", "step", "dateTo", "dateFrom", "totalItems"],
  data() {
    return {
      cntDay: 0,
    };
  },
  created() {
    console.log('ok', this.bodyItems)
    this.cntDay = this.dateTo - this.dateFrom + 1;
  },
  methods: {
    watchGeo(geo, id, sendId, itemText) {
      this.$emit("watchGeo", geo, id, sendId, itemText);
    },
    goToApps(itemGeo) {
      this.$emit("goToApps", itemGeo);
      localStorage.setItem('geoItem', itemGeo);
    },
    goToStep(itemId, itemName) {
      this.$emit("goToStep", itemId.id);
      console.log('itemId', itemId.text.name);
      localStorage.setItem('companyName', itemId.text.name);
    },
  },
};
</script>

<style scoped lang="scss">
.next__step {
  white-space: nowrap;
  img {
    position: relative;
    top: 3.5px;
  }
}

tfoot {
  position: relative;

  tr {
    height: 50px;
    padding-bottom: 20px;
    background-color: #242424;

    td {
      color: white;
      font-family: HalvarR;
      text-align: center;
    }
  }
  &:before {
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(90deg, #ff6700, #ff2a00, #830be2, #00f);
    padding: 1px;
  }
}
</style>

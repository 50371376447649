<template>
  <div>
    <div class="wrapper_modal">
      <div class="modal__template">
        <div
          v-if="newItem[0].delay"
          class="left"
        >
          <p>{{ $t('delays') }}</p>
          <div class="modal__label">
            <div
              v-for="itemDelay of newItem"
              :key="itemDelay.id"
            >
              {{ itemDelay.delayFormat }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="left"
        >
          <p>{{ $t('offers') }}</p>
          <div class="modal__label">
            <div
              v-for="itemOffers of newItem"
              :key="itemOffers.id"
            >
              {{ itemOffers }}
            </div>
          </div>
        </div>
        <div class="right" />
        <div class="btn__block">
          <button
            class="close"
            @click="close"
          >
            {{ $t('close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "SeeOffers",
  props: ["item"],
  data() {
    return {
      newItem: this.item,
      showModal: true,
    };
  },
  methods: {
    close() {
      this.$emit("close", {
        showModal: this.showModal,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-left: 5vw;
}

.next-step {
  position: relative;
  img {
    position: absolute;
    top: 10.5px;
    right: 10px;
    height: 20px;
  }
}
.modal {
  &__label {
    margin-top: 11px;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    max-height: 380px;
    overflow-y: scroll;
    width: 100%;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 20px;
    div {
      width: max-content;
    }
  }

  &__template {
    display: block !important;
    width: 620px;

    p:first-child {
      font-size: 20px;
    }
  }
}
p {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #e2e2e2;
}

.btn__block {
  bottom: 10px;
  position: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    background: #ff2a00;

    &.close {
      background: #404041;
    }

    &:hover {
      color: white;
    }
  }
}
</style>

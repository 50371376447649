<template>
  <div>
    <main-header :login="true" />
    <div class="main-block queue">
      <div class="home">
        <div class="top__block">
          <h1>
            {{ $t('queue') }}
          </h1>
        </div>
        <div class="type-device">
          <multiselect
            v-model="searchDeviceValue"
            class="modal__select--single"
            :multiple="false"
            track-by="name"
            label="name"
            :options="deviceList"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            @input="devicePage(searchDeviceValue)"
          />
        </div>
        <div class="tabs">
          <router-link
            class="tab"
            :to="{ path: '/queue/auto' }"
          >
            {{ $t('auto_pushes') }}
          </router-link>
          <router-link
            class="tab"
            :to="{ path: '/queue/everyday' }"
          >
            {{ $t('daily_pushes') }}
          </router-link>
          <router-link
            class="tab"
            :to="{ path: '/queue/moment' }"
          >
            {{ $t('instant_pushes') }}
          </router-link>
        </div>
        <div class="first-tab">
          <div class="wrapper">
            <div class="scroll_container">
              <div
                id="sort-bar"
                style="margin-top: 0"
                class="sort-bar anal"
              >
                <filters-multiselect
                  :placeholder="$t('geo')"
                  :multiple="true"
                  :model="searchGeoValue"
                  :options="geoList"
                  @searchItem="geoPage"
                />
                <filters-multiselect
                  :placeholder="$t('applications')"
                  :multiple="true"
                  :model="searchAppValue"
                  :options="appsList"
                  @searchItem="appPage"
                />
                <filters-multiselect
                  :placeholder="$t('task')"
                  :multiple="true"
                  :track-by-title="true"
                  :model="searchTitleValue"
                  :options="titleList"
                  @searchItem="namePage"
                />
              </div>
              <div class="table__info">
                <div class="count__list">
                  <label class="modal__label">{{ $t('show_records') }}</label>
                  <multiselect
                    v-model="valueEntries"
                    :options="listOptions"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :placeholder="$t('ten')"
                    class="count__multiselect"
                    @input="limitPage(valueEntries)"
                  />
                </div>
                <calendar-block
                  :min-date="true"
                  :max-date="false"
                  :date-from="dateFrom"
                  :date-to="dateTo"
                  :full-calendar="true"
                  @searchDateRange="searchDateRange"
                />
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{{ $t('send_date') }}</th>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('geo') }}</th>
                    <th>{{ $t('applications') }}</th>
                    <th>{{ $t('title') }}</th>
                    <th>{{ $t('text') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in queueList"
                    :key="item.id"
                  >
                    <td>{{ item.id_notify }}</td>
                    <td>{{ item.date_send | formatDate }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                      <div
                        v-if="item.geo.length > 3"
                        class="all__geo"
                        @click="watchGeo(item.geo)"
                      >
                        <img src="../assets/images/info.svg">
                      </div>
                      <div v-else>
                        <span
                          v-for="itemGeos in item.geo"
                          :key="itemGeos.id"
                        >
                          {{ itemGeos }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="item.apps.length > 1"
                        class="all__geo"
                        @click="watchApp(item.apps)"
                      >
                        <img src="../assets/images/info.svg">
                      </div>
                      <div v-else>
                        <span
                          v-for="itemApps in item.apps"
                          :key="itemApps.id"
                        >
                          {{ itemApps.name }}
                        </span>
                      </div>
                    </td>
                    <td>{{ item.push.title.en }}</td>
                    <td>{{ item.push.description.en }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul
              v-if="showPagination"
              class="pagination"
            >
              <li
                v-for="pag in pagQueue"
                :key="pag"
                :class="{ active: paginationPage_default === pag }"
                @click="paginationPage(pag)"
              >
                {{ pag }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <see-geo
        v-if="showSeeGeoModal"
        :item="itemGeo"
        @close="showSeeGeoModal = !showSeeGeoModal"
      />
      <see-app
        v-if="showSeeAppModal"
        :item="itemApp"
        @close="showSeeAppModal = !showSeeAppModal"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import MainHeader from "@/components/header/header.vue";
import Multiselect from "vue-multiselect";
import { HTTP } from "@/../axios-config";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import calendarBlock from "@/components/elements/calendarBlock.vue";
import AnalyticsTitle from "@/components/elements/analyticsTitle.vue";
import seeGeo from "@/components/pushPage/seeGeo.vue";
import seeApp from "@/components/pushPage/seeApp.vue";

export default {
  name: "queuePage",
  components: {
    calendarBlock,
    filtersMultiselect,
    Multiselect,
    MainHeader,
    AnalyticsTitle,
    seeGeo,
    seeApp,
  },
  data() {
    return {
      dateFrom: new Date(),
      dateTo: new Date(),
      dateFromFormater: "",
      dateToFormater: "",
      listOption_default: 10,
      paginationPage_default: 1,
      listOptions: ["10", "25", "50"],
      valueEntries: "",
      queueList: [],
      geoList: [],
      appsList: [],
      titleList: [],
      searchAppValue: [],
      searchGeoValue: [],
      searchTitleValue: [],
      viewGeoPage_default: "all",
      viewNamePage_default: "all",
      viewAppPage_default: "all",
      viewType_default: "1",
      itemGeo: [],
      showSeeGeoModal: false,
      itemApp: [],
      showSeeAppModal: false,
      showPagination: false,
      pagQueue: '',
      deviceList: [],
      searchDeviceValue: null,
      deviceType: null,
    };
  },
  methods: {
    getDeviceType() {
      const vm = this;
      HTTP.get(`/front/type/device/`)
        .then(response => {
          console.log('resp', response.data.data);
          vm.deviceList = response.data.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    devicePage(item) {
      this.deviceType = item;
      localStorage.setItem('deviceType', JSON.stringify(this.deviceType));
      this.getQueue()
    },
    loadDevice() {
      let savedOption = JSON.parse(localStorage.getItem('deviceType'));
      let android = {
        id: 2,
        name: 'android',
        ident: 'android',
      };
      this.searchDeviceValue = android;
      this.deviceType = android;
      if (savedOption) {
        this.deviceType = savedOption;
        this.searchDeviceValue = savedOption;
        this.searchDateRange(this.dateFrom, this.dateTo);
      }
    },
    watchGeo(geoItems) {
      this.itemGeo = geoItems;
      this.showSeeGeoModal = true;
    },
    watchApp(appItems) {
      this.itemApp = appItems;
      this.showSeeAppModal = true;
    },
    getQueue() {
      if (this.$route.params.type === 'auto') {
        this.getPushesQueue("1");
      }
      if (this.$route.params.type === 'everyday') {
        this.getPushesQueue("2");
      }
      if (this.$route.params.type === 'moment') {
        this.getPushesQueue("3");
      }
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getQueue()
    },
    appPage(appId) {
      if (appId.length < 1) {
        this.viewAppPage_default = "all";
      } else {
        this.viewAppPage_default = appId[0].id;
        for (let i = 1; i < appId.length; i++) {
          this.viewAppPage_default += `,${appId[i].id}`;
        }
      }
      this.getQueue();
    },
    geoPage(geoId) {
      if (geoId.length < 1) {
        this.viewGeoPage_default = "all";
      } else {
        this.viewGeoPage_default = geoId[0].code;
        for (let i = 1; i < geoId.length; i++) {
          this.viewGeoPage_default += `,${geoId[i].code}`;
        }
      }
      this.getQueue();
    },
    namePage(nameId) {
      if (nameId.length < 1) {
        this.viewNamePage_default = "all";
      } else {
        this.viewNamePage_default = nameId[0].id;
        for (let i = 1; i < nameId.length; i++) {
          this.viewNamePage_default += `,${nameId[i].id}`;
        }
      }
      this.getQueue();
    },
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getQueue();
    },
    dateFormatter(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = (month < 10) ? `0${month}` : month;
      day = (day < 10) ? `0${day}` : day;

      return `${year}-${month}-${day}`;
    },
    searchDateRange(dateFrom, dateTo) {
      this.dateFromFormater = this.dateFormatter(dateFrom);
      this.dateToFormater = this.dateFormatter(dateTo);

      this.showCalendar = false;

      this.getQueue();
    },
    getPushesQueue(id) {
      const vm = this;
      HTTP.get(
        `/front/analytics/queue?limit=${this.listOption_default}&page=${this.paginationPage_default}&geo=${this.viewGeoPage_default}&apps=${this.viewAppPage_default}&type=${id}&dateFrom=${this.dateFromFormater}&dateTo=${this.dateToFormater}&notifyIds=${this.viewNamePage_default}&typeDevice=${this.deviceType.ident}`
      )
        .then((response) => {
          vm.geoList = response.data.data.filter.geo;
          vm.appsList = response.data.data.filter.applications;
          vm.titleList = response.data.data.filter.title;
          vm.queueList = response.data.data.content;
          vm.pagQueue = response.data.data.pages;

          if (vm.pagQueue > 1) {
            vm.showPagination = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            vm.$router.push({ name: "Login" });
          }
        });
    },
  },
  created() {
  },
  mounted() {
    this.getDeviceType();

    this.loadDevice();
    // this.getQueue();
  },
  watch: {
    $route(to, from) {
      this.getQueue()
    }
  },
};
/* eslint-disable */
</script>

<style lang="scss" scoped>
.first-tab {
  height: auto;
}
.wrapper {
  height: 100%;
  max-height: 60vh;
}

button {
  min-width: 60px !important;
  height: 52px;

  @media only screen and (max-width: 767px) {
    height: 0;
  }
}

button:hover {
  transform: scale(0.8);
}
</style>
<style lang="scss">
.modal__main {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__main .wrapper_modal__window {
  text-align: center;
}

.main-block {
  width: 90vw;
  height: 100vh;
  display: block;
  align-items: flex-start;
  justify-content: center;
  padding: 120px 80px;

  @media only screen and (max-width: 767px) {
    padding: 85px 15px;
    overflow-y: scroll;
  }
}
h1 {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  display: flex;
  align-items: center;
  color: #e2e2e2;
  margin-top: -25px;
}
.btn-block {
  display: flex;
  justify-content: space-between;
  width: 50px;
  position: absolute;
  right: 32px;

  button {
    background: transparent;
    border: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    min-width: 60px;
  }
}
.app-page {
  width: 90vw;
}
.top__block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
}
thead {
  background: rgba(226, 226, 226, 0.1);
  height: 43px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #e2e2e2;
  height: 43px;

  tr {
    position: relative !important;
    &::after {
      content: " ";
      top: 40px;
      left: 0;
      width: 100%;
      position: absolute;
      border-radius: 8px;
      background: linear-gradient(to right, #ff6700, #ff2a00, #830be2, #0000ff);
      padding: 1px;
    }
    th {
      height: 43px;
    }
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }
}
tbody {
  text-align: center;
  tr {
    height: 60px;
    border-color: unset !important;
    border-style: unset !important;
    border-width: unset !important;
    transition: .2s;

    &:hover {
      background: #444444;
    }
    td {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #e2e2e2;
      max-width: 320px;
      padding: 3px;
      min-width: 75px;
    }

    &:nth-child(even) {
      background: rgb(82, 82, 82, 0.24);
      &:hover {
        background: #444444;
      }
    }
  }
}
.item {
  border: 1px solid;
  width: max-content;
  padding: 10px 20px;
  min-width: 100px;
  margin-bottom: 10px;
}
.add_btn {
  padding: 10px 20px;
  margin-bottom: 10px;
}
.add-push__modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}
.add-push__modal-item {
  background: white;
  padding: 20px;
  text-align: center;
}
.wrapper {
  width: 100%;
  overflow-y: unset;
  margin-top: 15px;
  position: relative;
}

.push__counter {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #e2e2e2;
  margin-bottom: 10px;
  padding-right: 2px;
  text-align: right;
}

.delete_btn {
  border: none;
  background: transparent;
  color: #e2e2e2;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;

  img {
    width: 40px;

    @media only screen and (max-width: 767px) {
      top: -21px;
      position: relative;
    }
  }
}

.see_btn {
  border: none;
  background: transparent;
  color: #e2e2e2;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;
}
.back_btn {
  border: none;
  background: transparent;
  color: #e2e2e2;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;
}
.tabs {
  width: 90vw !important;
  height: unset !important;
  background: transparent !important;
}
.vmt__tab-item {
  background: transparent !important;
  width: 90vw !important;
}
.vmt__nav,
.tabs__content {
  background: #161616 !important;
}
.vmt__nav {
  display: block !important;
}
.vmt__nav__items .vmt__nav__item {
  width: 319px;
  color: #7c7c7c;
}
.vmt__nav__items .vmt__nav__item.active {
  color: #e2e2e2 !important;
}
.vmt__tab__slider {
  background: #ff2a00 !important;
}
.first_tab {
  display: flex;
  flex-wrap: wrap;
}
.icon {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon.edit {
  background: url(../assets/images/edit.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  &:hover {
    transform: scale(0.8);
  }
}
.icon.copy {
  background: url(../assets/images/copy.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;

  &:hover {
    // background: url(../assets/images/copy_hover.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    transform: scale(0.8);
  }
}
.icon.delete {
  background: url(../assets/images/delete_outline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;

  &:hover {
    //   background: url(../assets/images/delete_outline-hover.svg);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    transform: scale(0.8);
    fill: #ff2a00;
  }
}
.all__geo {
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #830be2;
  }
}
#sort-bar {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  padding: 0 2px;

  @media only screen and (max-width: 767px) {
    display: block;
  }
  div {
    width: 23%;
    min-width: 297px;

    @media only screen and (max-width: 767px) {
      width: auto;
      min-width: auto;
    }
  }
  .multiselect__tags {
    margin-top: 18px;
    min-height: 39px !important;
  }
  .modal_select .multiselect__select {
    top: 10px !important;
    right: -127px !important;
  }
}

.two_inputs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div {
    min-width: 141px !important;

    .multiselect__select {
      top: 17px !important;
      right: -48px !important;
    }

    .multiselect__tags {
      min-width: 95%;
      margin-top: 8px !important;
    }
  }
}

.vld-icon {
  svg {
    stroke: #ff2a00 !important;
  }
}

.overflowHidden,
.overflowHidden1 {
  overflow: hidden;
  height: 82vh;
}


.queue {
  .tabs {
    display: flex;
    .tab {
      list-style: none;
      text-align: center;
      cursor: pointer;
      padding: 0.9rem 1rem;
      letter-spacing: .0892857143em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-size: .875rem;
      font-weight: 500;
      white-space: normal;
      transition: background .1s ease;
      position: relative;
      overflow: hidden;
      min-width: 90px;
      max-width: 360px;
      user-select: none;
      width: 100%;
      text-decoration: none;
      color: #e2e2e2 !important;
      transition: .3s;
      &:hover {
        background: hsla(0,0%,100%,.18);
      }

      &.router-link-active {
        border-bottom: 2px solid #ff2a00;
      }
    }
  }
}
</style>

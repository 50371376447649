import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './locales/en';
import ua from './locales/ua';
import ru from './locales/ru';

Vue.use(VueI18n);

const messages = {
  en,
  ua,
  ru,
};

export default new VueI18n({
  locale: 'en', // Текущий язык
  fallbackLocale: 'ua', // Резервный язык
  messages,
});
<template>
  <div>
    <main-header :login="false" />
    <div class="main-block">
      <p class="error__title">
        404
      </p>
      <p class="error__subtitle">
        {{ $t('page_not_found') }}
      </p>
      <button
        class="error__button"
        @click="goToMain"
      >
        {{ $t('go_to_home') }}
      </button>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";

export default {
  name: `Error`,
  components: {
    MainHeader,
  },
  methods: {
    goToMain() {
      this.$router.push({ name: "push" });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  width: calc(100vw - 160px);
  height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 80px;
  flex-direction: column;

  p {
    color: #fafafa;
    font-family: "HalvarR";
  }

  .error {
    &__title {
      font-size: 160px;
      margin: 0;
    }

    &__subtitle {
      font-size: 24px;
    }

    &__button {
      border-radius: 8px;
      width: 150px;
      height: 40px;
      background: #ff2a00;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border: none;
      color: #fafafa;
      font-family: "HalvarR";
    }
  }
}
</style>

export default {
  "personal_account": "Personal account",
  "login": "Login",
  "password": "Password",
  "sign_in": "Sign in",
  "sign_up": "Sign up",
  "my_apps": "My applications",
  "push_notifications_list": "List of push notifications",
  "analytics": "Analytics",
  "additional": "More",
  "queue": "Queue",
  "postbacks": "Postbacks",
  "tags": "Tags",
  "applications": "Applications",
  "push_notifications": "Push notifications",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "pwa": "PWA",
  "android": "Android",
  "ios": "iOS",
  "edit": "Edit",
  "device_type": "Device type",
  "onesignal_api_key": "Onesignal API Key",
  "onesignal_app_id": "Onesignal APP ID",
  "market_url": "Market URL",
  "create": "Create",
  "add_app": "Adding an app",
  "list_of_push_notifications": "List of push notifications",
  "country": "Country",
  "language": "Language",
  "delay": "Delay",
  "name": "Name",
  "geo": "GEO",
  "offers": "Offers",
  "tags_plural": "Tags",
  "show_records": "Show entries",
  "actions": "Actions",
  "edit_push": "Edit push",
  "exclude_from_mailing": "Exclude from mailing",
  "postback": "Postback",
  "action_performed": "Action performed",
  "incomplete_action": "Incomplete action",
  "set_up_postbacks": "Don't forget to set up postbacks for 'Registration/Deposit' events if you use the option of accounting for completion/non-completion of 'Registration/Deposit' events",
  "choose_offer": "Choose an offer",
  "no_selection_push": "If you do not select anything, the push will be sent to everyone regardless of the offer",
  "item": "Item",
  "delay_time": "Delay time",
  "icon_url": "Icon URL",
  "picture_url": "Picture URL",
  "link_on_open": "Link on open",
  "translation_language": "Translation language",
  "title": "Title",
  "message": "Message",
  "add_translation": "Add translation",
  "auto_push": "Auto push",
  "mailing_list": "Mailing list",
  "geo_sent_to": "Sent to",
  "delivered": "Delivered",
  "error": "Error",
  "not_sent": "Not sent",
  "clicks": "Clicks",
  "ctr": "CTR",
  "download_xlsx": "Download XLSX",
  "date_from": "Date from",
  "date_to": "Date to",
  "apply": "Apply",
  "selected_geo": "Selected GEOs",
  "close": "Close",
  "view_report": "View report",
  "daily_pushes": "Daily pushes",
  "instant_pushes": "Instant pushes",
  "offer_settings": "Offer settings",
  "offer_name": "Offer name",
  "add_offer": "Add offer",
  "history": "Offer history",
  "tag_name": "Tag name",
  "add_tag": "Add tag",
  "select_device_type": "Select device type",
  "next_step": "Next step",
  "auto_push_by_conversion": "Auto push (by conversions)",
  "daily_sending": "Daily sending",
  "instant_sending": "Instant sending",
  "exclude_apps_from_mailing": "Exclude apps from mailing",
  "select_country": "Select a country",
  "exclude_geo_from_mailing": "Exclude GEO from mailing",
  "select_event": "Select an event",
  "all_without_offers": "To all without offers",
  "close_tab": "Close tab",
  "day": "day",
  "hour": "hour",
  "minutes": "minutes",
  "send_immediately": "Send immediately",
  "upload_files": "Upload files",
  "select_files": "Select files",
  "are_you_sure": "Are you sure?",
  "cancel": "Cancel",
  "back": "Back",
  "confirm_delete_push": "Are you sure you want to delete the push permanently?",
  "push_copied": "Push copied and added to the top of the list",
  "optional_step": "Optional step. If you want to send the push to specific users, enter their External ID below",
  "external_id": "External ID",
  "constant_sending": "Constant sending",
  "select_push_time": "Select push sending time",
  "number_of_days": "Number of days* to send",
  "send_by_local_time": "Send by local time",
  "skip_step": "If you skip this step, the push will be sent immediately after creation",
  "select_sending_time_date": "Select sending time and date",
  "clear_time": "Clear time",
  "push_created": "Push created",
  "push_scheduled": "Push scheduled for the set time",
  "add_more_push": "Add another push",
  "back_to_push_list": "Back to push list",

  "push_edited": "Push edited",
  "push_changes_saved": "Push changes saved",
  "page_not_found": "Page not found",
  "go_to_home": "Go to home",
  "overall_statistics": "Overall statistics",
  "application": "Application",
  "previous": "Previous",
  "to_apps": "To applications",
  "total": "Total",
  "app_name_placeholder": "Application name",
  "market_url_placeholder": "Market URL",
  "onesignal_api_key_placeholder": "N4ZmYzgxYTAtZTdjNC00N6E8LWJkMzItZWQwY2VkYWRhNTMy",
  "onesignal_app_id_placeholder": "fc137bb5-92ee-5cd4-bcff-7c52b0f04658",
  "select_tags": "Select tags",
  "app_added_to_list": "Application added to the list",
  "return_to_app_list": "Return to app list",
  "something_went_wrong": "Something went wrong",
  "contact_fixers": "Contact the fixers",
  "go_to_fixers": "Okay, I'll go to the fixers",
  "language_english": "English",
  "language_ukrainian": "Ukrainian",
  "more": "More",
  "create_push_notification": "Create push notification",
  "add_push": "Add push",
  "delays": "Delays",
  "pushes": "Pushes",
  "min_length_message": "Minimum name length is 3 characters",
  "status": "Status",
  "postback_history": "Postback history",
  "offers_plural": "Offers",
  "ten": "10",
  "first": "First",
  "prev": "Previous",
  "next": "Next",
  "last": "Last",
  "enter_offer_name": "Enter the offer name",
  "great": "Great",
  "offer_added": "Offer added",
  "tag": "Tag",
  "twenty_five": "25",
  "yes": "Yes",
  "auto_pushes": "Auto pushes",
  "task": "Task",
  "send_date": "Send date",
  "text": "Text",
  "active": "Active",
  "archived": "Archived",
  "none": "None",
  "register": "Register",
  "create_push": "Create push",
  "choose_type_device": "Select device type",
  "choose_type_push": "Select notify type",
  "select_app": "Select app",
  "select_time": "Select the time to send the push",
  "select_time_after": "After how much time to send the push",
  "if_select_time": "If you skip this step, the push will be sent immediately after creation",
  "select_date_time": "Select the time and date of sending",
  "time": "Time",
  "sel_num_days": "Number of days",
  "time_send": "Sending time",
  "download_file": "Download file",
  "error_hour": "Hour cannot be greater than 23",
  "error_minutes": "Minutes cannot be greater than 59",
  "send_push": "Send push",
  "error_message": "Fill in all the required fields \"Title\", \"Text\", \"Language\""
};
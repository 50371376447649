<template>
  <div>
    <ul
      v-if="Desktop"
      class="nav"
    >
      <li>
        <router-link to="/apps">
          {{ $t('my_apps') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'push' }">
          {{ $t('push_notifications_list') }}
        </router-link>
      </li>
      <li
        class="js-dropdown-main"
        style="position: relative"
      >
        <a
          href="#"
          @click="showDropdown = !showDropdown"
        >
          {{ $t('analytics') }}
        </a>
        <ul class="dropdown js-dropdown-body">
          <li @click="closeDropdown(showDropdown)">
            <router-link :to="{ path: '/analytics/auto' }">
              {{ $t('analytics') }}
            </router-link>
          </li>
          <li @click="closeDropdown(showDropdown)">
            <router-link :to="{ path: '/queue/auto' }">
              {{ $t('queue') }}
            </router-link>
          </li>
        </ul>
      </li>
      <li
        class="js-dropdown-main"
        style="position: relative"
      >
        <a href="#">{{ $t('more') }}</a>
        <ul class="dropdown js-dropdown-body">
          <li @click="closeDropdown(showDropdown2)">
            <router-link :to="{ path: '/postback' }">
              {{ $t('postbacks') }}
            </router-link>
          </li>
          <li @click="closeDropdown(showDropdown2)">
            <router-link :to="{ path: '/tags' }">
              {{ $t('tags_plural') }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>

    <div
      v-if="!Desktop"
      class="nav__mobile"
    >
      <ul class="nav">
        <li class="push_button">
          <button
            :title="$t('create_push_notification')"
            class="add-push__btn"
            @click="showAddPush"
          >
            <span>{{ $t('add_push') }}</span>
            <img
              src="../../assets/images/plus.svg"
              alt=""
            >
          </button>
        </li>
      </ul>
      <div
        id="toggle"
        @click="openMenu = !openMenu"
      >
        <input type="checkbox">
        <span />
      </div>

      <div
        v-if="openMenu"
        id="menu"
      >
        <ul class="nav">
          <li>
            <router-link to="/apps">
              {{ $t('my_apps') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'push' }">
              {{ $t('push_notifications_list') }}
            </router-link>
          </li>
          <li @click="reloadPage">
            <router-link to="/analytics/auto">
              {{ $t('auto_pushes') }}
            </router-link>
          </li>
          <li @click="reloadPage">
            <router-link to="/analytics/everyday">
              {{ $t('daily_pushes') }}
            </router-link>
          </li>
          <li @click="reloadPage">
            <router-link to="/analytics/moment">
              {{ $t('instant_pushes') }}
            </router-link>
          </li>
          <li>
            <router-link to="/postback">
              {{ $t('postbacks') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "MainHeader",
  data() {
    return {
      Desktop: true,
      showDropdown: false,
      showDropdown2: false,
      openMenu: false,
      window: {
        width: 0,
      },
    };
  },
  created() {
    window.addEventListener(`resize`, this.handleResize);
    this.handleResize();
  },
  methods: {
    reloadPage() {
      console.log('reload')
      location.reload();
    },
    showAddPush() {
      this.$router.push({ name: "addPush" });
    },
    closeDropdown(dropdowns) {
      dropdowns = !dropdowns;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      if (this.window.width < 767) {
        this.Desktop = false;
      }
    },
    // openMenu() {
    //   let box = document.getElementById(`menu`);
    //   box.classList.toggle(`active`);
    // },
  },
};
</script>

<style lang="scss" scoped>
// Colors
$color-one: #34495e;
$color-two: #ecf0f1;
$color-three: #c0392d;
$color-four: #2980d9;

* {
  box-sizing: border-box;
}

#menu {
  background: #323232;
  width: 100vw;
  height: auto;
  padding: 50px 0;
  color: $color-two;
  display: flex;
  visibility: visible;
  opacity: 1;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 3px;
  transition: all 300ms ease-in-out;
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 9;

  &.active {
    top: 65px;
  }

  .nav {
    margin: 0;
  }

  #logo {
    font-size: 25px;
    font-weight: 700;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin: 10px 0;
    }
  }
}

.nav__mobile {
  position: relative;

  .push_button {
    top: -21px;
  }
}

#toggle {
  position: absolute;
  width: 40px;
  height: 36px;
  //right: -70px;
  top: -13px;
  transition: all 300ms ease-in-out;
  z-index: 10;

  input {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 30px;
    height: 16px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked {
      + span {
        background: transparent;
        left: 0;

        &:after {
          transform: rotate(45deg) translate(-1px, -12px);
          left: -7px;
          top: 11px;
          background: #fff;
        }

        &:before {
          transform: rotate(-45deg) translate(-4px, 16px);
          left: -7px;
          background: #fff;
          top: -12px;
        }
      }
    }
  }

  span {
    position: absolute;
    width: 30px;
    height: 3px;
    background: #e2e2e2;
    margin-top: 10px;
    transition: all 0.1s;
    left: 0;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 3px;
      background: #e2e2e2;
      top: -8px;
      left: 0;
      transition: all 0.3s;
    }

    &:after {
      top: 8px;
    }
  }
}

.nav {
  margin-left: 100px;
  margin-right: auto;
  align-items: center;
  display: flex;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  li {
    margin-right: 45px;

    &.push_button {
      position: absolute;
      right: 145px;

      @media only screen and (max-width: 767px) {
        right: 0;
        margin: 0 !important;
      }
    }
  }

  a {
    font-family: HalvarR;
    color: #e2e2e2;
    text-decoration: none;
    transition: 0.5s;
    white-space: nowrap;

    &:hover {
      color: #ff2a00;
    }

    &.router-link-exact-active {
      color: #ff2a00;
    }
  }

  .add-push__btn {
    background: blue;
    border: none;
    width: max-content;
    height: 40px;
    border-radius: 6px;
    color: white;
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 8px 15px;

    img {
      margin-left: 10px;

      @media only screen and (max-width: 767px) {
        margin-left: 0;
      }
    }

    span {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: 17px;
  left: -10px;
  background: #323232;
  padding: 45px 0 17px;
  border-radius: 0 0 8px 8px;

  li {
    padding: 5px 27px;
    margin-right: 0px !important;

    &:has(a.router-link-exact-active) {
      background: #494949;
      padding: 10px 27px;
    }

    a {
      white-space: nowrap;
    }
  }
}

.js-dropdown-main {
  &:hover {
    .js-dropdown-body {
      display: block;
    }
  }

  .js-dropdown-body {
    display: none;
  }
}
</style>

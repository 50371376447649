export default {
  "personal_account": "Особистий кабінет",
  "login": "Логін",
  "password": "Пароль",
  "sign_in": "Увійти",
  "sign_up": "Зареєструватися",
  "my_apps": "Мої додатки",
  "push_notifications_list": "Список пуш-повідомлень",
  "analytics": "Аналітика",
  "additional": "Додатково",
  "queue": "Черга",
  "postbacks": "Постбеки",
  "tags": "Теги",
  "applications": "Додатки",
  "push_notifications": "Пуш-повідомлення",
  "enabled": "Увімкнено",
  "disabled": "Вимкнено",
  "pwa": "PWA",
  "android": "Android",
  "ios": "iOS",
  "edit": "Редагування",
  "device_type": "Тип пристрою",
  "onesignal_api_key": "Onesignal API Key",
  "onesignal_app_id": "Onesignal APP ID",
  "market_url": "URL Market",
  "create": "Створити",
  "add_app": "Додавання додатка",
  "list_of_push_notifications": "Список пуш-повідомлень",
  "country": "Країна",
  "language": "Мова",
  "delay": "Затримка",
  "name": "Назва",
  "geo": "ГЕО",
  "offers": "Офери",
  "tags_plural": "Теги",
  "show_records": "Показувати записи",
  "actions": "Дії",
  "edit_push": "Редагувати пуш",
  "exclude_from_mailing": "Виключити з розсилки",
  "postback": "Постбек",
  "action_performed": "Виконана дія",
  "incomplete_action": "Невиконана дія",
  "set_up_postbacks": "Не забудьте налаштувати постбеки на події 'Реєстрація/Депозит', якщо ви використовуєте опцію обліку звершення/не звершення подій 'Реєстрація/Депозит'",
  "choose_offer": "Вибір оффера",
  "no_selection_push": "Якщо ви нічого не обираєте, то пуш буде відправлятися всім незалежно від оффера",
  "item": "Елемент",
  "delay_time": "Час затримки",
  "icon_url": "URL іконки",
  "picture_url": "URL картинки",
  "link_on_open": "Посилання при відкритті",
  "translation_language": "Мова перекладу",
  "title": "Заголовок",
  "message": "Повідомлення",
  "add_translation": "Додати переклад",
  "auto_push": "Авто пуш",
  "mailing_list": "Розсилка",
  "geo_sent_to": "Відправлено до",
  "delivered": "Доставлено",
  "error": "Помилка",
  "not_sent": "Не відправлено",
  "clicks": "Кліків",
  "ctr": "CTR",
  "download_xlsx": "Завантажити XLSX",
  "date_from": "Дата від",
  "date_to": "Дата до",
  "apply": "Застосувати",
  "selected_geo": "Обрані ГЕО",
  "close": "Закрити",
  "view_report": "Дивитися звіт",
  "daily_pushes": "Щоденні пуші",
  "instant_pushes": "Моментальні пуші",
  "offer_settings": "Налаштування оффера",
  "offer_name": "Ім'я оффера",
  "add_offer": "Додати оффер",
  "history": "Історія оффера",
  "tag_name": "Назва тега",
  "add_tag": "Додавання тега",
  "select_device_type": "Виберіть тип пристрою",
  "next_step": "Наступний крок",
  "auto_push_by_conversion": "Автопуш (за конверсіями)",
  "daily_sending": "Щоденне відправлення",
  "instant_sending": "Моментальне відправлення",
  "exclude_apps_from_mailing": "Виключити додатки з розсилки",
  "select_country": "Виберіть країну",
  "exclude_geo_from_mailing": "Виключити ГЕО з розсилки",
  "select_event": "Виберіть подію",
  "all_without_offers": "Всім без офферів",
  "close_tab": "Закрити вкладку",
  "day": "день",
  "hour": "година",
  "minutes": "хвилини",
  "send_immediately": "Надіслати негайно",
  "upload_files": "Завантажити файли",
  "select_files": "Виберіть файли",
  "are_you_sure": "Ви впевнені?",
  "cancel": "Відміна",
  "back": "Назад",
  "confirm_delete_push": "Ви впевнені, що хочете видалити пуш безповоротно?",
  "push_copied": "Пуш скопійовано та додано догори списку",
  "optional_step": "Необов'язковий крок. Якщо ви хочете надіслати пуш певним юзерам, то впишіть у поле нижче їхній External ID",
  "external_id": "External ID",
  "constant_sending": "Постійне надсилання",
  "select_push_time": "Вибрати час відправлення пуша",
  "number_of_days": "Кількість днів* для відправлення",
  "send_by_local_time": "Відправити за локальним часом",
  "skip_step": "Якщо пропустити цей пункт, пуш надішлеться одразу після створення",
  "select_sending_time_date": "Вибрати час і дату відправлення",
  "clear_time": "Очистити час",
  "push_created": "Пуш створено",
  "push_scheduled": "Пуш буде відправлено в запланований час",
  "add_more_push": "Додати ще пуш",
  "back_to_push_list": "Повернутися до списку пушів",

  "push_edited": "Пуш відредаговано",
  "push_changes_saved": "Зміни у пуші збережені",
  "page_not_found": "Сторінку не знайдено",
  "go_to_home": "На головну",
  "overall_statistics": "Загальна статистика",
  "application": "Додаток",
  "previous": "Попередня",
  "to_apps": "До додатків",
  "total": "Загалом",
  "app_name_placeholder": "Назва додатка",
  "market_url_placeholder": "URL Market",
  "onesignal_api_key_placeholder": "N4ZmYzgxYTAtZTdjNC00N6E8LWJkMzItZWQwY2VkYWRhNTMy",
  "onesignal_app_id_placeholder": "fc137bb5-92ee-5cd4-bcff-7c52b0f04658",
  "select_tags": "Обрати теги",
  "app_added_to_list": "Додаток додано до списку",
  "return_to_app_list": "Повернутися до списку додатків",
  "something_went_wrong": "Щось пішло не так",
  "contact_fixers": "Зверніться до фахівців для виправлення",
  "go_to_fixers": "Добре, піду до фахівців",
  "language_english": "Англійська",
  "language_ukrainian": "Українська",
  "more": "Додатково",
  "create_push_notification": "Створити пуш-повідомлення",
  "add_push": "Додати пуш",
  "delays": "Затримки",
  "pushes": "Пуші",
  "min_length_message": "Мінімальна довжина імені - 3 символи",
  "status": "Статус",
  "postback_history": "Історія постбеків",
  "offers_plural": "Офери",
  "ten": "10",
  "first": "Перша",
  "prev": "Попередня",
  "next": "Наступна",
  "last": "Остання",
  "enter_offer_name": "Введіть назву офера",
  "great": "Чудово",
  "offer_added": "Офер додано",
  "tag": "Тег",
  "twenty_five": "25",
  "yes": "Так",
  "auto_pushes": "Автопуші",
  "task": "Завдання",
  "send_date": "Дата відправлення",
  "text": "Текст",
  "active": "Активно",
  "archived": "В архіві",
  "none": "Немає",
  "register": "Реєстрація",
  "create_push": "Створення пуша",
  "choose_type_device": "Оберіть тип девайсу",
  "choose_type_push": "Оберіть тип пуша",
  "select_app": "Оберіть прілу",
  "select_time": "Вибрати час відправлення пуша",
  "select_time_after": "Через який час відправити пуш",
  "if_select_time": "Якщо пропустити цей пункт, пуш відправиться одразу після створення",
  "select_date_time": "Вибрати час і дату відправлення",
  "time": "Час",
  "sel_num_days": "Кількість днів",
  "time_send": "Час відправлення",
  "download_file": "Завантажити файлом",
  "error_hour": "Година не може бути більшою за 23",
  "error_minutes": "Хвилини не можуть бути більшими за 59",
  "send_push": "Відправити пуш",
  "error_message": "Заповніть усі обов’язкові поля \"Заголовок\", \"Текст\", \"Мова\""
};
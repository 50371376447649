<template>
  <div>
    {{ title + ' ' }}
    <span v-if="type == 'auto'">{{ $t('auto_push') }}</span>
    <span v-if="type == 'moment'">{{ $t('instant_push') }}</span>
    <span v-if="type == 'everyday'">{{ $t('daily_push') }}</span>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: `AnalyticsTitle`,
  props: ["type", "title"],
};
</script>

<template>
  <div>
    <div class="wrapper_modal">
      <div class="modal__template">
        <div class="left">
          <p>{{ $t('tags_plural') }}</p>
          <div class="modal__label">
            <div
              v-for="itemTag in newItem"
              :key="itemTag.id"
            >
              {{ itemTag.name }}
            </div>
          </div>
        </div>
        <div class="btn__block">
          <button
            class="close"
            @click="close"
          >
            {{ $t('close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "AppPush",
  props: ["item"],
  data() {
    return {
      newItem: this.item,
      showModal: true,
    };
  },
  methods: {
    close() {
      this.$emit("close", {
        showModal: this.showModal,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-left: 5vw;
}

.next-step {
  position: relative;
  img {
    position: absolute;
    top: 10.5px;
    right: 10px;
    height: 20px;
  }
}
.modal {
  &__label {
    margin-top: 11px;
    margin-bottom: 60px;
    display: inline-block;
    max-height: 380px;
    overflow-y: scroll;
    width: auto;
  }

  &__template {
    display: block !important;
    width: 620px;

    p:first-child {
      font-size: 20px;
    }
  }
}
p {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #e2e2e2;
}

.btn__block {
  bottom: 10px;
  position: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    background: #ff2a00;

    &.close {
      background: #404041;
    }

    &:hover {
      color: white;
    }
  }
}
</style>

<template>
  <div class="apps">
    <loader v-if="isLoading" />
    <div class="wrapper_modal app">
      <div class="top__block modal">
        <h1>{{ $t('add_app') }}</h1>
        <button
          class="add-push__btn"
          @click="close"
        >
          ✕
        </button>
      </div>
      <form
        class="modal__push add__app"
      >
        <div class="left">
          <label class="modal__label">
            {{ $t('device_type') }}
            <multiselect
              v-model="deviceType"
              class="modal__select--single multiselect"
              label="name"
              :placeholder="$t('device_type')"
              :options="deviceList"
              :multiple="false"
              :preselect-first="true"
              :searchable="false"
              :allow-empty="false"
              @select="devicePage"
            />
          </label>
          <label
            class="modal__label"
            for="title"
            style="position: relative"
          >
            {{ $t('name') }}
            <input
              id="title"
              v-model="newItem.name"
              required
              :class="{ 'is-invalid': submitted && $v.newPush.Name.$error }"
              class="modal__unput required name"
              type="text"
              :placeholder="$t('app_name_placeholder')"
              maxlength="50"
              minlength="3"
            >
          </label>
          <label
            class="modal__label"
            for="img"
          >
            {{ $t('market_url') }}
            <input
              id="img"
              v-model="newItem.marketUrl"
              required
              :class="{ 'is-invalid': submitted && $v.newPush.Name.$error }"
              class="modal__unput required"
              type="text"
              :placeholder="$t('market_url_placeholder')"
              minlength="3"
            >
          </label>
        </div>
        <div class="right">
          <label
            class="modal__label"
            for="ico"
          >
            {{ $t('onesignal_api_key') }}
            <input
              id="ico"
              v-model="newItem.bearerKey"
              required
              :class="{ 'is-invalid': submitted && $v.newPush.Name.$error }"
              class="modal__unput required"
              type="text"
              :placeholder="$t('onesignal_api_key_placeholder')"
              minlength="3"
            >
          </label>
          <label
            class="modal__label"
            for="text"
          >
            {{ $t('onesignal_app_id') }}
            <input
              id="text"
              v-model="newItem.oneSignalAppId"
              required
              :class="{ 'is-invalid': submitted && $v.newPush.Name.$error }"
              class="modal__unput required"
              type="text"
              :placeholder="$t('onesignal_app_id_placeholder')"
              minlength="3"
            >
          </label>
          <label
            class="modal__label"
            for="group"
          >
            {{ $t('tags_plural') }}
            <multiselect
              v-model="newTagsArr"
              class="modal__select--single multiselect group"
              label="name"
              track-by="name"
              :placeholder="$t('select_tags')"
              :options="tagsList"
              :multiple="true"
              :searchable="false"
            />
          </label>
          <div class="btn__block">
            <button
              :disabled="isDisabled === true"
              @click="checkPush"
            >
              {{ $t('create') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { HTTP } from "@/../axios-config";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import loader from "@/components/elements/loader.vue";

export default {
  name: "addApp",
  components: {
    loader,
    filtersMultiselect,
    Multiselect,
  },
  props: ['device', 'tagsList'],
  data() {
    return {
      isLoading: false,
      showAddApp: true,
      submitted: false,
      newItem: [],
      newTagsArr: [],
      typesApp: [],
      deviceList: [],
      searchDeviceValue: {
        id: 2,
        name: 'android',
      },
      deviceType: this.device,
      isDisabled: false,
    };
  },
  validations: {
    newItem: {
      name: { required },
      oneSignalAppId: { required },
      marketUrl: { required },
      bearerKey: { required },
    },
    newTagsArr: { required }
  },
  methods: {
    getIds (arr) {
      return arr.map(app => app.id);
    },
    devicePage(item) {
      console.log('item', item);
      this.deviceType = item;
    },
    getDeviceType() {
      HTTP.get(`/front/type/device/`)
        .then(response => {
          console.log('resp', response.data.data);
          this.deviceList = response.data.data;
        })
        .catch(err => {
          console.error(error);
        });
    },
    checkPush(e) {

      if (this.newTagsArr.length < 1) {
        $(`.modal__select--single.group`).addClass(`border__red`);
        setTimeout(function () {
          $(`.modal__select--single.group`).removeClass(`border__red`);
        }, 3000);
        e.preventDefault();

        return ;
      }
      if (this.newItem.name.length < 1 || this.newItem.bearerKey.length < 1 || this.newItem.marketUrl.length < 1 || this.newItem.oneSignalAppId.length < 1) {
        return;
      }
      e.preventDefault();
      this.push();
    },
    push() {
      this.isLoading = true;
      const vm = this;
      this.isDisabled = true;
      HTTP.post(`/front/applications/`, {
          bearer_key: this.newItem.bearerKey,
          market_url: this.newItem.marketUrl,
          name: this.newItem.name,
          one_signal_app_id: this.newItem.oneSignalAppId,
          tags: this.getIds(this.newTagsArr),
          type_device: this.deviceType.ident
        },
      )
        .then(function () {
          vm.isDisabled = false;
          vm.isLoading = false;
          vm.$router.push({ name: `finalStepApp` });
        })
        .catch(function (error) {
          console.log(error);
          vm.isLoading = false;
          vm.isDisabled = false;

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }
        });
    },
    close: function () {
      this.$emit(`close`, {
        showAddApp: this.showAddApp,
      });
    },
  },

  beforeMount() {
    this.getDeviceType();
    console.log('this device', this.device);
  }
};
</script>

<style lang="scss" scoped>
h1 {
  margin-left: 5vw;
}

.wrapper_modal {
  padding: 120px 0 !important;
}
</style>
<style lang="scss">
.apps {
  .loader {
    top: 0;
    left: 0;
    height: 100vh;
  }
}
.add__app {
  .multiselect {
    margin-top: 10px;
  }

  .multiselect__select {
    right: 0 !important;
    top: 13px !important;
  }
}

.top__block.modal {
  width: 89%;

  .add-push__btn {
    color: white;
    font-size: 30px;
    background: transparent;
    transition: 0.5s;

    &:hover {
      color: #ff2a00;
    }
  }
}

.wrapper_modal {
  position: absolute;
  top: 0;
  left: 0;
  padding: 120px 0;
  background: #242424;
  height: 100vh;
  width: 100%;
  //overflow: scroll;
  z-index: 1;

  .main__title {
    width: 780px;
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #e2e2e2;
  }
}

.modal__template,
.modal__push {
  display: flex;
  margin: 30px auto;
  background: rgba(217, 217, 217, 0.07);
  border-radius: 10px;
  width: max-content;
  padding: 10px 30px 50px 50px;
  position: relative;
}

.modal__push {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.modal__label {
  display: flex;
  flex-direction: column;
  width: 360px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: rgba(226, 226, 226, 0.7);
  margin-bottom: 20px;
  margin-right: 20px;
}

select,
input {
  margin-top: 8px;
  height: 40px;
  background: transparent;
  border-radius: 10px;
  padding: 0 10px;
  color: #e2e2e2;
  border: 1px solid rgba(204, 206, 221, 0.2);
}

.btn__block {
  width: 360px;
  right: 50px;
  bottom: 60px;
  //position: absolute;
  display: flex;
  justify-content: right;

  button {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #e2e2e2;
    border: none;
    padding: 11px 35px;
    border-radius: 8px;
    justify-content: center;
    background: #ff2a00;
    margin-top:20px
  }

  .send {
    background: #ff2a00;
    width: 360px !important;
    height: 40px !important;
    transition: 0.5s;
    text-align: center;

    &:hover {
      background: transparent;
    }
  }

  .close {
    width: auto !important;
    height: 40px !important;
    background: transparent;
    transition: 0.5s;

    &:hover {
      color: #ff2a00;
    }
  }
}

.border__red {
  border: 2px solid #ff2a00 !important;
  border-radius: 8px;
}

.main-block {
  width: 93vw !important;
}

@media (max-width: 767px) {
  .wrapper_modal.app {
    width: 101vw;
  }
  .modal__push.add__app {
    flex-direction: column;
    width: 70%;

    input, .modal__select--single {
      width: 70%;
    }

    .btn__block {
      position: unset;

      button {
        width: 70% !important;
        margin-top: 30px;
      }
    }
  }

  .app-page {
    .first-tab {
      height: 66vh !important;
    }
  }

  .main-block {
    height: 85vh !important;
  }
}

.multiselect__single,
.multiselect__element span {
  text-transform: capitalize !important;
}
</style>

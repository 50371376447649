<template>
  <div>
    <div class="wrapper_modal">
      <h1>{{ $t('geo') }}</h1>
      <div class="modal__template">
        <p>{{ $t('selected_geo') }}</p>
        <div class="modal__label">
          {{ newItem }}
        </div>
        <div class="btn__block">
          <button
            class="close"
            @click="close"
          >
            {{ $t('close') }}
          </button>
          <button
            v-if="analyticsPage"
            class="next-step"
            @click="getGeoAnalytics"
          >
            {{ $t('view_report') }}
            <img src="../../assets/images/double_arrow.svg">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "seeGeoAnalytics",
  props: [
    "item",
    "analyticsPage",
    "pushId",
    "sendPushId",
    "typeAnalytics",
    "dateFrom",
    "dateTo",
    "pushName"
  ],
  data() {
    return {
      newItem: this.item,
      newHiddenItem: this.itemHidden,
      showModal: true,
    };
  },
  methods: {
    close() {
      this.$emit("close", {
        showModal: this.showModal,
      });
    },
    getGeoAnalytics() {
      // eslint-disable-next-line
      localStorage.setItem('geoName', this.pushName);
      this.$router.push(`/analytics/${this.typeAnalytics}/${this.dateFrom}/${this.dateTo}/${this.sendPushId}`);

    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-left: 5vw;
}

.next-step {
  position: relative;
  img {
    position: absolute;
    top: 10.5px;
    right: 10px;
    height: 20px;
  }
}
.modal {
  &__label {
    margin-top: 11px;
    margin-bottom: 60px;
    display: inline-block;
    max-height: 380px;
    overflow-y: scroll;
    width: 100%;
    word-break: break-word;
  }

  &__template {
    display: block !important;
    width: 620px;

    p:first-child {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      width: 70%;
    }
  }
}
p {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #e2e2e2;
}

.btn__block {
  bottom: 10px;
  position: unset;
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  button {
    background: #ff2a00;

    &.close {
      background: #404041;
    }

    &:hover {
      color: white;
    }
  }
}
</style>

export default {
  "personal_account": "Личный кабинет",
  "login": "Логин",
  "password": "Пароль",
  "sign_in": "Войти",
  "sign_up": "Зарегистрироваться",
  "my_apps": "Мои приложения",
  "push_notifications_list": "Список пуш-уведомлений",
  "analytics": "Аналитика",
  "additional": "Дополнительно",
  "queue": "Очередь",
  "postbacks": "Постбеки",
  "tags": "Теги",
  "applications": "Приложения",
  "push_notifications": "Пуш-уведомления",
  "enabled": "Включено",
  "disabled": "Выключено",
  "pwa": "PWA",
  "android": "Android",
  "ios": "iOS",
  "edit": "Редактирование",
  "device_type": "Тип устройства",
  "onesignal_api_key": "Onesignal API Key",
  "onesignal_app_id": "Onesignal APP ID",
  "market_url": "URL Market",
  "create": "Создать",
  "add_app": "Добавление приложения",
  "list_of_push_notifications": "Список пуш-уведомлений",
  "country": "Страна",
  "language": "Язык",
  "delay": "Задержка",
  "name": "Название",
  "geo": "ГЕО",
  "offers": "Офферы",
  "tags_plural": "Теги",
  "show_records": "Показывать записи",
  "actions": "Действия",
  "edit_push": "Редактировать пуш",
  "exclude_from_mailing": "Исключить из рассылки",
  "postback": "Постбек",
  "action_performed": "Совершенное действие",
  "incomplete_action": "Несовершенное действие",
  "set_up_postbacks": "Не забудьте настроить постбеки на события 'Регистрация/Депозит', если вы используете опцию учета свершения/не свершения событий 'Регистрация/Депозит'",
  "choose_offer": "Выбор оффера",
  "no_selection_push": "Если вы ничего не выбираете, то пуш будет отправляться всем независимо от оффера",
  "item": "Элемент",
  "delay_time": "Время задержки",
  "icon_url": "URL иконки",
  "picture_url": "URL картинки",
  "link_on_open": "Ссылка при открытии",
  "translation_language": "Язык перевода",
  "title": "Заголовок",
  "message": "Сообщение",
  "add_translation": "Добавить перевод",
  "auto_push": "Автопуш",
  "mailing_list": "Рассылка",
  "geo_sent_to": "Отправлено в",
  "delivered": "Доставлено",
  "error": "Ошибка",
  "not_sent": "Не отправлено",
  "clicks": "Кликов",
  "ctr": "CTR",
  "download_xlsx": "Скачать XLSX",
  "date_from": "Дата от",
  "date_to": "Дата до",
  "apply": "Применить",
  "selected_geo": "Выбранные ГЕО",
  "close": "Закрыть",
  "view_report": "Смотреть отчет",
  "daily_pushes": "Ежедневные пуши",
  "instant_pushes": "Моментальные пуши",
  "offer_settings": "Настройка оффера",
  "offer_name": "Имя оффера",
  "add_offer": "Добавить оффер",
  "history": "История оффера",
  "tag_name": "Название тега",
  "add_tag": "Добавление тега",
  "select_device_type": "Выберите тип устройства",
  "next_step": "Следующий шаг",
  "auto_push_by_conversion": "Автопуш (по конверсиям)",
  "daily_sending": "Ежедневная отправка",
  "instant_sending": "Моментальная отправка",
  "exclude_apps_from_mailing": "Исключить приложения из рассылки",
  "select_country": "Выберите страну",
  "exclude_geo_from_mailing": "Исключить ГЕО из рассылки",
  "select_event": "Выберите событие",
  "all_without_offers": "Всем без офферов",
  "close_tab": "Закрыть вкладку",
  "day": "день",
  "hour": "час",
  "minutes": "минуты",
  "send_immediately": "Отправить незамедлительно",
  "upload_files": "Загрузить файлы",
  "select_files": "Выберите файлы",
  "are_you_sure": "Вы уверены?",
  "cancel": "Отмена",
  "back": "Назад",
  "confirm_delete_push": "Вы уверены, что хотите удалить пуш безвозвратно?",
  "push_copied": "Пуш скопирован и добавлен вверх списка",
  "optional_step": "Необязательный шаг. Если вы хотите отправить пуш определенным юзерам, введите в поле ниже их External ID",
  "external_id": "External ID",
  "constant_sending": "Постоянная отправка",
  "select_push_time": "Выберите время отправки пуша",
  "number_of_days": "Количество дней* для отправки",
  "send_by_local_time": "Отправить по локальному времени",
  "skip_step": "Если пропустить этот пункт, пуш отправится сразу после создания",
  "select_sending_time_date": "Выберите время и дату отправки",
  "clear_time": "Очистить время",
  "push_created": "Пуш создан",
  "push_scheduled": "Пуш будет отправлен в запланированное время",
  "add_more_push": "Добавить еще пуш",
  "back_to_push_list": "Вернуться к списку пушей",

  "push_edited": "Пуш отредактирован",
  "push_changes_saved": "Изменения в пуше сохранены",
  "page_not_found": "Страница не найдена",
  "go_to_home": "На главную",
  "overall_statistics": "Общая статистика",
  "application": "Приложение",
  "previous": "Предыдущая",
  "to_apps": "К приложениям",
  "total": "Всего",
  "app_name_placeholder": "Название приложения",
  "market_url_placeholder": "URL Market",
  "onesignal_api_key_placeholder": "N4ZmYzgxYTAtZTdjNC00N6E8LWJkMzItZWQwY2VkYWRhNTMy",
  "onesignal_app_id_placeholder": "fc137bb5-92ee-5cd4-bcff-7c52b0f04658",
  "select_tags": "Выберите теги",
  "app_added_to_list": "Приложение добавлено в список",
  "return_to_app_list": "Вернуться к списку приложений",
  "something_went_wrong": "Что-то пошло не так",
  "contact_fixers": "Обратитесь к специалистам для исправления",
  "go_to_fixers": "Хорошо, пойду к специалистам",
  "language_english": "Английский",
  "language_ukrainian": "Украинский",
  "more": "Дополнительно",
  "create_push_notification": "Создать пуш-уведомление",
  "add_push": "Добавить пуш",
  "delays": "Задержки",
  "pushes": "Пуши",
  "min_length_message": "Минимальная длина имени - 3 символа",
  "status": "Статус",
  "postback_history": "История постбеков",
  "offers_plural": "Офферы",
  "ten": "10",
  "first": "Первая",
  "prev": "Предыдущая",
  "next": "Следующая",
  "last": "Последняя",
  "enter_offer_name": "Введите имя оффера",
  "great": "Отлично",
  "offer_added": "Оффер добавлен",
  "tag": "Тег",
  "twenty_five": "25",
  "yes": "Да",
  "auto_pushes": "Автопуши",
  "task": "Задание",
  "send_date": "Дата отправки",
  "text": "Текст",
  "active": "Активно",
  "archived": "В архиве",
  "none": "Нет",
  "register": "Регистрация",
  "create_push": "Создание пуша",
  "choose_type_device": "Выберите тип девайса",
  "choose_type_push": "Выберите тип пуша",
  "select_app": "Выберите приложение",
  "select_time": "Выбрать время отправки пуша",
  "select_time_after": "Через какое время отправить пуш",
  "if_select_time": "Если пропустить этот пункт, пуш отправиться сразу после создания",
  "select_date_time": "Выбрать время и дату отправки",
  "time": "Время",
  "sel_num_days": "Кол-во дней",
  "time_send": "Время отправки",
  "download_file": "Загрузить файлом",
  "error_hour": "Час не может быть больше 23",
  "error_minutes": "Минуты не могут быть больше 59",
  "send_push": "Отправить пуш",
  "error_message": "Заполните все обязательные поля \"Заголовок\", \"Текст\", \"Язык\"",
};
<template>
  <div>
    <div class="wrapper_modal add-push">
      <div class="wrapper_modal__window">
        <div class="final__text-center">
          <h1 class="final__title">
            {{ $t('push_created') }}
          </h1>
          <p class="final__subtitle">
            {{ $t('push_scheduled') }}
          </p>
        </div>
        <div class="btns__block">
          <button
            class="back-step__btn"
            @click="backToPush"
          >
            {{ $t('return_to_push_list') }}
          </button>
          <button
            class="next-step__btn"
            @click="addNewPush"
          >
            {{ $t('add_more_pushes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinalPush",
  data() {
    return {
      addPush: false,
    };
  },
  methods: {
    backToPush() {
      this.$router.push({ name: "push" });
    },
    addNewPush() {
      this.$router.push({ name: "addPush" });
    },
  },
};
</script>

<style lang="scss" scoped>
.final {
  &__text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #e2e2e2;
  }

  &__subtitle {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #e2e2e2;
    opacity: 0.7;
  }
}
</style>
